import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import VideoPlayer from "../Video";
import { useMatchMedia } from "../Sections/Hooks/useMatchMedia";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { ExitIcon } from "../SvgIcons";
// import styles2 from "./styles2.module.css";
import Loader2 from "../Loader/index2";

const Testimonial = ({ content }) => {
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [modalDesc, setModalDesc] = useState("");
  const sliderRef = useRef(null);

  const handleClick1 = (element) => {
    setModal(true);
    setModalTitle(element.title);
    setModalSubTitle(element.subtitle);
    setModalDesc(element.popupText);
  };

  const videoHandler = (e) => {
    // if (e.target.getAttribute("data-vid")) {
    //   let video = document.getElementById(e.target.getAttribute("data-vid"));
    //   let div = document.getElementById(e.target.getAttribute("data-id"));
    //   console.log(video);
    //   console.log(div);
    //   if (window.getComputedStyle(div).display === "block") {
    //     document.querySelectorAll(".testVideo").forEach((vid) => vid.pause());
    //     video
    //       .play()
    //       .then((Response) => {
    //         console.log({ Response });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //     document.getElementById(
    //       e.target.getAttribute("data-id")
    //     ).style.display = "none";
    //   } else {
    //     document.querySelectorAll(".testVideo").forEach((vid) => vid.pause());
    //     let pausedStatus = video.pause();
    //     console.log({ pausedStatus });
    //     document.getElementById(
    //       e.target.getAttribute("data-id")
    //     ).style.display = "block";
    //   }
    // }
    if (e.target.getAttribute("data-vid")) {
      let video = document.getElementById(e.target.getAttribute("data-vid"));
      document.querySelectorAll(".testVideo").forEach((vid) => {
        let a = vid.closest(".slick-active");
        let b = vid.getAttribute("data-rid");
        if (a) {
          if (vid.paused) {
            vid.play();
            let c = vid.nextElementSibling.id;
            if (c == "playBtn-" + b) {
              if (!vid.paused && vid.nextElementSibling.classList.contains(c)) {
                vid.nextElementSibling.style.display = "none";
              }
            }
          } else {
            vid.pause();
            let c = vid.nextSibling.id;
            if (vid.paused && vid.nextElementSibling.classList.contains(c)) {
              vid.nextElementSibling.style.display = "block";
            }
          }
        }
      });
    }
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    smallMobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 500 },
      items: 1,
    },
  };
  const [isDesktop] = useMatchMedia("(min-width: 1400px)", true);
  const [isMobile] = useMatchMedia("(max-width: 768px)", true);
  const {
    slides,
    theme,
    colorCodeOnPlayButton,
    title,
    subTitle,
    videoHeight,
    videoWidth,
    invertDesign,
  } = content || null;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselData, setCarouselData] = useState([]);
  const [a, setA] = useState(0);
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const [carouselData1, setCarouselData1] = useState([]);
  const [a1, setA1] = useState(0);
  const cRef = useRef(null);
  const [arrayLoad, setArrayLoad] = useState("");
  const [slideArrayLoad, setSlideArrayLoad] = useState("");
  useEffect(() => {
    getDataForCurrentSlide(currentSlide);
    getDataForCurrentSlide1(currentSlide1);
  }, [currentSlide]);

  // Function to get data for the current slide
  const getDataForCurrentSlide = (slideIndex) => {
    setArrayLoad(1);
    let startIndex = slideIndex;
    if (startIndex < 0) {
      startIndex = slides.length - 1;
      setA(slides.length - 1);
    }
    if (startIndex >= slides.length) {
      startIndex = 0;
      setA(0);
    }

    let slideLength = slides.length;
    let endIndex = startIndex + 1;
    endIndex = endIndex <= slideLength ? endIndex : slides.length;

    // Extract the data from the JSON file for the current range
    const slicedData = slides.slice(startIndex, endIndex);
    if (slicedData) {
      setCarouselData(slicedData);
    }

    setTimeout(function () {
      setArrayLoad("");
    }, 2000);
  };
  const getDataForCurrentSlide1 = (slideIndex) => {
    setSlideArrayLoad(1);
    let startIndex = slideIndex;
    let dataLength = slides.length;
    if (startIndex < 0) {
      startIndex = 1;
      setA1(0);
    }
    if (startIndex >= slides.length) {
      startIndex = 1;
      setA1(0);
    }

    let slideLength = slides.length;
    let endIndex = startIndex + 4;
    endIndex = endIndex <= slideLength ? endIndex : slides.length;
    let elements = endIndex - startIndex;

    if (elements < 4) {
      startIndex = 1;
      endIndex = 4;
      setA1(0);
    }

    // Extract the data from the JSON file for the current range
    let slicedData = slides.slice(startIndex, endIndex);
    if (slicedData) {
      setCarouselData1(slicedData);
    }
    setTimeout(function () {
      setSlideArrayLoad("");
    }, 1000);
  };

  const previous1 = () => {
    alert("abcccdd");
    let i = currentSlide;
    document.getElementById(
      "transformContainer"
    ).style.transform = `translateX(-${(i - 1) * 400}px)`;
    setCurrentSlide(i - 1);
    if (i - 1 < 0) {
      setCurrentSlide(0);
      if (isDesktop) {
        document.getElementById(
          "transformContainer"
        ).style.transform = `translateX(200px)`;
      } else {
        document.getElementById(
          "transformContainer"
        ).style.transform = `translateX(0px)`;
      }
    }
  };

  const Next = () => {
    let i = currentSlide;
    console.warn({
      gggg: (i + 1) * 400,
      status: i + 1 <= slides.length,
      length: slides.length,
      i,
    });
    document.getElementById(
      "transformContainer"
    ).style.transform = `translateX(-${(i + 1) * 400}px)`;
    setCurrentSlide(i + 1);
    if (i + 1 >= slides.length) {
      document.getElementById(
        "transformContainer"
      ).style.transform = `translateX(-${slides.length * 400}px)`;
      setCurrentSlide(slides.length);
    }
  };

  const handleAfterClick = (currentSlide) => {
    document.querySelectorAll(".player").forEach((play) => {
      play.style.display = "block";
    });
  };
  const handleBeforeClick = (previousIndex, nextIndex) => {
    let counta = a;
    let countb = 0;
    if (previousIndex == 0) {
      countb = counta - 1;
    } else {
      countb = counta + 1;
    }

    setA(countb);
    getDataForCurrentSlide(countb);
    return false;
  };

  const handleAfterClick1 = (currentSlide) => {
    document.querySelectorAll(".player1").forEach((play) => {
      play.style.display = "block";
    });
  };
  const handleBeforeClick1 = (oldIndex, newIndex) => {
    let counta = a1;
    let countb = 0;

    if (newIndex == 0 && oldIndex == 3) {
      countb = counta + 1;
    } else if (newIndex < oldIndex) {
      countb = counta - 1;
    } else {
      countb = counta + 1;
    }

    setA1(countb);
    if (newIndex == 3 || newIndex == 0) {
      getDataForCurrentSlide1(countb);
    }
    return false;
  };

  const settings = {
    className: "center vSlider",
    centerMode: true,
    infinite: true,
    centerPadding: "20%",
    slidesToShow: 1,
    speed: 300,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: handleBeforeClick1,
    afterChange: handleAfterClick1,
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className={styles.ButtonGroup}>
        <p onClick={() => previous()}>previous</p>
        <p onClick={() => next()}>Next</p>
      </div>
    );
  };

  return (
    <section>
      <div className={styles.main_container}>
        {title && (
          <div className={styles.headingContainer}>
            {title && <h1>{title}</h1>}
            {subTitle && <p>{subTitle}</p>}
          </div>
        )}
        <div className={styles.container}>
          <Carousel
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            itemClass="m-0"
            autoPlay={false}
            infinite={true}
            containerClass="container"
            autoPlaySpeed={5000}
            keyBoardControl={true}
            responsive={responsive}
            beforeChange={handleBeforeClick}
            afterChange={handleAfterClick}
          >
            {carouselData.map((e, i) => {
              return (
                <div
                  className={`${styles.testimonialSlide} testimonialParent`}
                  key={i}
                >
                  <div className={styles.slideInfo}>
                    <div className={styles.slideInfoWidth}>
                      <div
                        className={styles.slideInfoQuote}
                        style={
                          invertDesign
                            ? { color: theme, width: "90%", margin: "auto" }
                            : {}
                        }
                      >
                        <p>{e.title}</p>
                      </div>
                      <div
                        className={styles.slideInfoAuthor}
                        style={
                          !invertDesign
                            ? {
                                color: theme,
                                width: "100%",
                                margin: "0",
                              }
                            : {}
                        }
                      >
                        {e.author}
                      </div>
                    </div>
                  </div>
                  {arrayLoad ? (
                    <div className={styles.loaderMargin}>
                      <Loader2 />
                    </div>
                  ) : (
                    <div className={styles.slideVideo}>
                      {e.video && (
                        <VideoPlayer
                          video={e.video}
                          id={`mob-${i}`}
                          theme={colorCodeOnPlayButton && theme}
                          height={"100%"}
                          position={true}
                          vclass={true}
                          rId={`${i}`}
                          clickFunction="new"
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

// Define custom arrow components
const CustomPrevArrow = ({ onClick }) => (
  <button className="slick-prev" onClick={onClick} title="previous">
    <img src="\images\ArrowRightSvg.svg" alt="previous" />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button className="slick-next" onClick={onClick} title="previous">
    Next
    <img src="\images\arrowSvg.svg" alt="previous" />
  </button>
);
export default Testimonial;
